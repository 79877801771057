<template>
    <div class="home">
        <!-- search -->
        <!-- <div class="search">
            <el-form class="form" :inline="true" :model="query" ref="search">
                <el-form-item label="职位名称">
                    <el-input v-model="query.name" placeholder="请输入职位名称" :clearable="true"></el-input>
                </el-form-item>
                <el-form-item label="职位类型">
                    <el-input v-model="query.title" placeholder="请输入职位类型" :clearable="true"></el-input>
                </el-form-item>
                <el-form-item label="展示状态">
                    <el-select v-model="query.display" placeholder="请选择展示状态" :clearable="true">
                        <el-option label="展示" value="true"></el-option>
                        <el-option label="隐藏" value="false"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSearch" v-loading="loadingOne">查询</el-button>
                    <el-button type="primary" @click="handleAdd" style="margin-left: 40px;">新建</el-button>
                </el-form-item>
            </el-form>
        </div> -->
        <!-- table -->
        <!-- 此处这么处理是因为：当弹框的时候，多选框会显示 -->
        <template v-if="!presentVisible"> 
            <div class="tablewrapper">
                <el-table ref="multipleTable" :data="list.list" tooltip-effect="dark" @selection-change="handleSelectionChange" stripe>
                    <!-- <el-table-column type="selection" width="50"></el-table-column> -->
                    <el-table-column type="index" width="40" label="#"></el-table-column>
                    <el-table-column prop="title" label="业务领域" min-width="60" align="center"></el-table-column>
                    <el-table-column prop="peopleZh" label="律师关联" min-width="60" align="center"></el-table-column>
                    <el-table-column prop="relateZh" label="案例关联" min-width="60" align="center"></el-table-column>
                    <!-- <el-table-column prop="type" label="职位类型" min-width="60" align="center"></el-table-column>
                    <el-table-column prop="city" label="城市" min-width="100" align="center"></el-table-column>
                    <el-table-column prop="number" label="招聘人数" min-width="100" align="center" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="displayZh" label="展示状态" min-width="50" align="center"></el-table-column> -->
                    <el-table-column prop="updatedAt" label="更新时间" :formatter="formatter" min-width="90" align="center" show-overflow-tooltip></el-table-column>

                    <el-table-column label="操作" min-width="120" align="center">
                        <template #default="scope">
                            <el-button size="mini" type="primary" @click="handleBrief(scope.$index, scope.row)">内容编辑</el-button>
                            <el-button size="mini" type="success" :loading="peopleLoading" @click="handleRelateLawyer(scope.$inex, scope.row)">律师关联</el-button>
                            <el-button size="mini" type="success" @click="handleRelateCase(scope.$inex, scope.row)">案例关联</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </template>
        <!-- 律师关联 -->
        <el-dialog :title="dialogTitle" v-model="lawyerVisible">
            <div style="text-align: center">
                <el-transfer style="text-align: left; display: inline-block;" 
                :button-texts="['移除', '添加']"
                :data="peopleList" v-model="peopleSelects"
                @change="handleManagePeople"
                filterable
                :titles="['未关联律师','已关联律师']"></el-transfer>
            </div>
            <div class="dialog-footer" style="margin-top: 30px;">
                <el-button @click="lawyerVisible = false" style="width: 100px;">取消</el-button>
                <el-button type="primary" @click="handlePeopleSure" :loading="loadingOne" style="width: 100px; margin-left: 40px;">保存</el-button>
                <div class="tips">tips：①先勾选。②再移除或添加。③最后取消或保存。</div>
            </div>
        </el-dialog>
        <!-- 案例关联 -->
        <el-dialog :title="dialogTitle" v-model="caseVisible" width="60%">
            <div style="text-align: center; margin-top: -30px;">
                   <el-radio-group v-model="caseType" @change="caseTypeChange">
                    <el-radio-button label="0">所有类型</el-radio-button>
                    <el-radio-button label="1">元诺动态</el-radio-button>
                    <el-radio-button label="2">元诺案例</el-radio-button>
                    <el-radio-button label="3">聚焦观察</el-radio-button>
                    <el-radio-button label="4">社会责任</el-radio-button>
                    <el-radio-button label="5">已经关联（<b>{{ caseSelects.length }}</b>）个</el-radio-button>
                </el-radio-group>
            </div>
            <!-- 案例选择 -->
           <div class="tablewrapper" style="margin-top: 10px;">
                <el-table ref="multipleCaseTable" :data="caseList" tooltip-effect="dark" @selection-change="handleCaseChange" stripe height="500">
                    <el-table-column type="selection" min-width="40"></el-table-column>
                    <el-table-column type="index" min-width="40" label="#"></el-table-column>
                    <el-table-column prop="title" min-width="140" label="文章标题" align="center" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="peopleZh" label="律师关联" min-width="50" align="center"></el-table-column>
                    <el-table-column prop="relateZh" label="案例关联" min-width="50" align="center"></el-table-column>
                    <el-table-column prop="updatedAt" label="更新时间" :formatter="formatter" min-width="90" align="center" show-overflow-tooltip></el-table-column>
                </el-table>
            </div>

            <div style="text-align: center; margin-top: 25px; ">
                <el-button @click="caseVisible = false" style="width: 100px;">取消</el-button>
                <el-button type="primary" @click="handleCaseSure" style="width: 100px; margin-left: 40px;">保存</el-button>
            </div>
        </el-dialog>
        <!-- 内容编辑 -->
        <template v-if="presentVisible">
            <div class="dialog-ckeditor-bg">
                <div class="ckeditor-header">
                    <el-button @click="presentVisible = false" style="width: 100px; margin: auto 0;">取消编辑</el-button>
                    <div class="ckeditor-title">{{ dialogTitle }}编辑</div>
                    <el-button type="primary" @click="handleBriefSure" style="width: 100px; margin: auto 0;">保存编辑</el-button>
                </div>
                <div class="dialog-ckeditor"> 
                    <ckeditor class="ckeditor" :editor="editor" @ready="ckeditorReady" v-model="editorData" :config="editorConfig"></ckeditor>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import ClassicEditor from '@/components/in-editor/core/ckeditor';
import UploadAdapter from '@/components/in-editor/core/uploadImageAdapter';
// import {fmt} from '@/assets/utils';
const Util = require('@/assets/utils');
import {
    getAllField as get,
    updateInfoJob as updateInfo,
    updateContentField as updateContent,
    getPeopleField as getPeople,
    updatePeopleField as updatePeople,
    getCaseField as allCase,
    updateCaseField as updateCase,
} from "@/api/api";
const code = 200
export default {
    name: "业务领域",
    data(){
        return{
            query: {
                page: 1,
                size: 10, 
                name: '',
                type: '',
                display: ''
            },
            addQuery: {
                id: '',
                name: '',
                city: '',
                type: '',
                number: '',
                display: ''
            },
            rules:{
                name: [{required: true, message: '请输入职位名称', trigger: 'blur'}],
                type: [{required: true, message: '请选择职位类型', trigger: 'blur'}],
                city: [{required: true, message: '请输入城市', trigger: 'blur'}],
                number: [{required: true, message: '请输入招聘人数', trigger: 'blur'}],
                display: [{required: true, message: '请选择展示状态', trigger: 'blur'}]
            },
            newQuery: {},
            list:{},
            currentPage: 1,
            selects: [],
            dialogTitle: '',
            dialogVisible: false,
            lawyerVisible: false,
            caseVisible: false,
            presentVisible: false,
            loadingOne: false,
            showshow: true,
            editor: ClassicEditor,
            // editorData: '<blockquote><p>好好学习，天天向上。</p></blockquote><figure class="image image-style-side"><img src="https://winnorm.oss-cn-chengdu.aliyuncs.com/manage/1620370055169_lawyer_8.jpeg"><figcaption>会议照片</figcaption></figure><p><span style="background-color:rgb(255,255,255);color:rgb(0,0,0);font-size:14px;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 李连杰律师，西南财大法律硕士，四川元诺律师事务所律师。1999年开始从事专职律师工作，主要为公司、企业、事业单位、政府部门提供法律顾问及诉讼服务工作。执业 22 年来，积累了经验，形成了自有特长和风格，擅长风险风控、纠纷化解、商务谈判，赢得客户信赖和肯定。</span></p><p><span style="background-color:rgb(255,255,255);color:rgb(0,0,0);font-size:14px;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 李连杰律师，西南财大法律硕士，四川元诺律师事务所律师。1999年开始从事专职律师工作，主要为公司、企业、事业单位、政府部门提供法律顾问及诉讼服务工作。执业 22 年来，积累了经验，形成了自有特长和风格，擅长风险风控、纠纷化解、商务谈判，赢得客户信赖和肯定。</span></p><p><span style="background-color:rgb(255,255,255);color:rgb(0,0,0);font-size:14px;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 李连杰律师，西南财大法律硕士，四川元诺律师事务律师。1999年开始从事专职律师工作，主要为公司、企业、事业单位、政府部门提供法律顾问及诉讼服务工作。执业 22 年来，积累了经验，形成了自有特长和风格，擅长风险风控、纠纷化解、商务谈判，赢得客户信赖和肯定。</span></p><figure class="image"><img src="https://winnorm.oss-cn-chengdu.aliyuncs.com/manage/1620370055169_lawyer_8.jpeg"><figcaption>参赛的地址</figcaption></figure><p>好好学习，天天向上。</p>',
            editorData: '',
            editorHandleId: '',
            editorConfig:{},
            peopleList: [],
            peopleSelects: [],
            handlePeopleId: 0,
            peopleLoading: false,
            caseType: 0,
            caseAllList: [],
            caseList:[],
            caseSelects: [],
            caseSelectIds: [],
            handleCaseId: 0,
        }
    },
    computed:{
    },
    methods:{
        // 时间格式化
        formatter(row,column){
            return Util.fmt(row.updatedAt)
        },
        getSearchList(){
            this.loadingOne = true
            get(this.query).then(res =>{
                if(res.data.code == code){
                    this.list = res.data.data
                }else{
                    this.$message({
                        type: 'error',
                        message: res.data.msg
                    })
                }
                this.loadingOne = false
            }).catch(() =>{
                this.$message({
                    type: 'error',
                    message:"查询失败,请重试或者联系管理员！！"
                })
                this.loadingOne = false
            })
        },
        // 律师关联
        handleRelateLawyer(index, row){
            this.handlePeopleId = row.id
            this.peopleList = []
            this.dialogTitle = '律师关联'
            getPeople({id: row.id}).then(res =>{
                console.log('律师关联 === ', res)
                if(res.data.code == code){
                    this.lawyerVisible = true
                    var list = res.data.data.all
                    for(var i = 0; i < list.length; i ++){
                        var obj = {
                            key: list[i].id,
                            label: list[i].name
                        }
                        this.peopleList.push(obj)
                    }
                    this.peopleSelects = res.data.data.selects
                }
            }).catch(() =>{
                this.$message({
                    type: 'error',
                    message:"查询失败,请重试或者联系管理员！！"
                })
                this.loadingOne = false
            })
        },
        handleManagePeople(){
            console.log(this.peopleSelects)

        },
        handlePeopleSure(){
            console.log(this.peopleSelects.join(','))
            updatePeople({id: this.handlePeopleId, people: this.peopleSelects.join(',')}).then(res =>{
                if(res.data.code == code){
                    this.$message({
                        message: res.data.msg,
                        type: "success"
                    });
                    this.lawyerVisible = false,
                    this.getSearchList()
                }else{
                    this.$message({
                        type: 'error',
                        message: res.data.msg
                    })
                }
            }).catch(() =>{
                this.$message({
                    type: 'error',
                    message:"更新失败,请重试或者联系管理员！！"
                })
            })
        },
        // 案例关联
        handleRelateCase(index, row){
            this.handleCaseId = row.id
            this.caseVisible = true
            this.dialogTitle = '案例关联'
            this.caseType = 0
            allCase({id: row.id}).then(res =>{
                if(res.data.code == code){
                    this.caseAllList = res.data.data.all
                    this.caseList = this.caseAllList[0]
                    this.caseSelects = res.data.data.selects
                    this.caseSelectIds = res.data.data.selectIds
                    this.caseTypeChange()
                }else{
                    this.$message({
                        type: 'error',
                        message: res.data.msg
                    })
                }
            }).catch(() =>{
                this.$message({
                    type: 'error',
                    message:"更新失败,请重试或者联系管理员！！"
                })
            })
        },
        caseTypeChange(){
            // this.$refs.multipleCaseTable.toggleRowSelection(0, true)
            if(this.caseType != '5'){
                this.caseList = this.caseAllList[this.caseType]
                if(this.caseList.length && this.caseSelectIds.length){
                    this.caseList.forEach((row) =>{
                        if(this.caseSelectIds.indexOf(row.id) >= 0){
                            this.$nextTick(function(){
                                this.$refs.multipleCaseTable.toggleRowSelection(row, true)
                            })
                            console.log('111 === ',row.id)
                        }
                        console.log('222 === ',row.id)
                    })
                }
            }else{
                this.caseList = this.caseSelects
                if(this.caseList.length){
                    this.$refs.multipleCaseTable.toggleAllSelection()
                }
            }
        },
        handleCaseChange(values){
            if(this.caseType == 0 || this.caseType == 5){
                this.caseSelectIds = []
                this.caseSelects = values
                values.forEach(item =>{
                    this.caseSelectIds.push(item.id)
                })
                console.log(this.caseSelectIds, this.caseSelects)
            }else{
                // 先删除对应type的所有数据
                var newArray = []
                for(var i = 0; i < this.caseSelects.length; i ++){
                    if(this.caseSelects[i].type != this.caseType){
                        newArray.push(this.caseSelects[i])
                    }
                }
                if(values.length){
                    newArray = newArray.concat(values)
                }
                this.caseSelects = newArray
                this.caseSelectIds = newArray.map(item => {return item.id})
                console.log('length === ', this.caseSelectIds.length, this.caseSelects.length, values.length)
            }
        },
        handleCaseSure(){
            updateCase({id: this.handleCaseId, relate: this.caseSelectIds.toString()}).then(res =>{
                if(res.data.code == code){
                    this.$message({
                        message: res.data.msg,
                        type: "success"
                    });
                    this.caseVisible = false,
                    this.getSearchList()
                }else{
                    this.$message({
                        type: 'error',
                        message: res.data.msg
                    })
                }
            }).catch(() =>{
                this.$message({
                    type: 'error',
                    message:"更新失败,请重试或者联系管理员！！"
                })
            })
            console.log(this.caseSelectIds.toString())
        },
        // 新增
        handleAdd(){
            this.dialogTitle = "新增"
            this.dialogVisible = true
            this.$nextTick(function(){
                this.$refs["addQuery"].resetFields()
                this.newQuery = Object.assign({}, this.addQuery)
            })
        },
        // 编辑
        handleEdit(index, row){
            this.dialogVisible = true
            this.dialogTitle = "编辑"
            this.$nextTick(function(){
                this.$refs['addQuery'].resetFields();
                this.newQuery = Object.assign({}, row)        
            })
        },
        // 删除
        handleDelete(index, row){
            this.requestDel(row.id)
        },
        // 新增或编辑之后的确定事件
        handleSure(){
            this.$refs["addQuery"].validate(valid =>{
                if(valid){
                    console.log(this.newQuery)
                    if(this.newQuery.id){
                        updateInfo(this.newQuery).then(res =>{
                            if(res.data.code == code){
                                this.$message({
                                    message: "更新成功",
                                    type: "success"
                                });
                                this.dialogVisible = false
                                this.getSearchList()
                            }else{
                                this.$message({
                                    message: res.data.msg,
                                    type: 'warning'
                                })
                            }
                        }).catch(err =>{
                            this.$message({
                                message: err.message,
                                type: 'warning'
                            })
                        })
                    }else{
                        create(this.newQuery).then(res =>{
                            if(res.data.code == code){
                                this.$message({
                                    message: "新增成功",
                                    type: "success"
                                });
                                this.dialogVisible = false
                                this.getSearchList()
                            }else{
                                this.$message({
                                    message: res.data.msg,
                                    type: 'warning'
                                })
                            }
                        }).catch(err =>{
                            this.$message({
                                message: err.message,
                                type: 'warning'
                            })
                        })
                    }
                }
            })
        },
        // 查询
        handleSearch(){
            this.getSearchList()
        },
        // 履历编辑
        handleBrief(index, row){
            this.presentVisible = true
            this.editorData = row.content
            this.editorHandleId = row.id
            this.dialogTitle = row.typeZh
        },
        // 履历编辑之后确定事件
        handleBriefSure(){
            updateContent({id: this.editorHandleId, content: this.editorData}).then(res =>{
                if(res.data.code == code){
                    this.$message({
                        message: "更新成功",
                        type: "success"
                    });
                    this.presentVisible = false
                    this.getSearchList()
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    })
                }
            }).catch(err =>{
                this.$message({
                    message: err.message,
                    type: 'warning'
                })
            })
        },
        // 请求删除
        requestDel(ids){
            this.$confirm('此操作将永久删除该数据，是否继续？', '提示',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() =>{
                del({ids: ids}).then(res =>{
                    if(res.data.code == code){
                        this.$message({
                            type: "success",
                            message: res.data.msg
                        })
                        this.getSearchList()
                    }else{
                        this.$message({
                            type: "error",
                            message: res.data.msg
                        })
                    }
                }).catch(() =>{
                    this.$message({
                        type: 'error',
                        message:"删除失败,请重试或者联系管理员！！"
                    })
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            })
        },
        // 编辑器
        ckeditorReady(editor){
            console.log(' === 编辑器初始化完成')
            editor.plugins.get("FileRepository").createUploadAdapter = loader =>{
                return new UploadAdapter(loader)
            }
        },
        // 图片上传回传
        uploadSuccess(res){
            this.newQuery.icon = res
        },
        // 批量删除事件
        handleBatchDelete(){
            if(this.selects.length){
                let ids = this.selects.map(item => item.id).toString();
                this.requestDel(ids)
            }else{
                this.$message({
                    message: '请先勾选要删除的数据！！！',
                    type: 'warning'
                })
            }
        },
        // 修改size
        handleSizeChange(val){
            this.query.size = val
            this.getSearchList()
        },
        // 修改page
        handleCurrentChange(val){
            this.query.page = val
            this.getSearchList()
        },
        // 批量选择
        handleSelectionChange(val){
            this.selects = val
        }

    },
    mounted(){
        this.getSearchList()
    },
    components:{
    }
}
</script>
<style lang="less">
.search .el-form-item{
    margin-right: 50px;
}
.block{
    margin-top: 10px;
}
.el-pagination{
    float: right;
}
.avatar-uploader {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 178px;
    height: 178px;
}
.avatar-uploader:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    width: 178px;
    /* height: 178px; */
    display: block;
}
.dialog-footer{
    text-align: center;
}
.dialog-footer .tips{
    line-height: 40px;
    color: red;
    /* background-color: yellow; */
    font-size: 10px;
    // text-align: right;
}
// 头部
.dialog-ckeditor-bg{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 500px;
    width: 1100px;
    height: 100%;
    width: 100%;
    background-color: white;
}
.ckeditor-header{
    height: 80px;
    width: 1100px;
    display: flex;
    margin: 0 auto;
}
.ckeditor-header-item{
    height: 100%;
}
.ckeditor-title{
    width: 100%;
    line-height: 80px;
    font-size: 20px;
    text-align: center;
    color: rgb(99, 99, 99);
}

.dialog-ckeditor{
    width: 1100px;
    margin: 0 auto;
}


// elementui 表格修改边框后下面还是有一条白线
.el-table::before {
    height: 0;  // 将高度修改为0
}

/* --------- EDITOR STYLES  ---------------------------------------------------------------------------------------- */

.editor__editable,
/* Classic build. */
main .ck-editor[role='application'] .ck.ck-content,
/* Decoupled document build. */
.ck.editor__editable[role='textbox'],
.ck.ck-editor__editable[role='textbox'],
/* Inline & Balloon build. */
.ck.editor[role='textbox'] {
	// width: 100%;
	// background: #fff;
    // background: rebeccapurple;
	font-size: 1em;
	line-height: 1.6em;
	min-height: var(--ck-sample-editor-min-height);
	padding: 1.5em 2em;
}
.ck-editor__editable {
  height: 800px;
}

</style>